<template>
  <v-container fluid>
    <v-card elevation="2" class="mb-6">
      <v-card-title class="headline primary white--text">
        Data Ownerships
        <v-spacer></v-spacer>
        <v-btn icon @click="getDocs()" color="white">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row dense class="mt-2">
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="UserID"
              :items="users"
              dense
              outlined
              hide-details
              small-chips
              item-text="name"
              item-value="id"
              label="Select User"
              @change="onUserChange"
              prepend-inner-icon="mdi-account"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4">
            <v-btn-toggle v-model="Conds" mandatory class="mt-1">
              <v-btn value="Y" small>Standard</v-btn>
              <v-btn value="N" small>Queries</v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="search"
              label="Search"
              dense
              outlined
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          v-model="dataOwnershipData"
          :loading="loader"
          :headers="headers"
          :items="authData"
          :search="search"
          :items-per-page="itemsPerPage"
          dense
          class="elevation-1 mt-4"
          :striped="true"
        >
          <template v-slot:item.ownerships.peer="{ item }">
            <v-select
              v-model="item.ownerships.peer"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.manager="{ item }">
            <v-select
              v-model="item.ownerships.manager"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.subordinate="{ item }">
            <v-select
              v-model="item.ownerships.subordinate"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.branch="{ item }">
            <v-select
              v-model="item.ownerships.branch"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.department="{ item }">
            <v-select
              v-model="item.ownerships.department"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.team="{ item }">
            <v-select
              v-model="item.ownerships.team"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.company="{ item }">
            <v-select
              v-model="item.ownerships.company"
              :items="authorizations"
              dense
              :disabled="Conds === 'N'"
              item-text="text"
              item-value="value"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.id="{ item }">
            <v-select
              v-model="item.QueryID"
              :items="Queries"
              dense
              :disabled="Conds === 'Y'"
              item-text="QName"
              item-value="id"
              hide-details
            ></v-select>
          </template>

          <template v-slot:item.ownerships.Active="{ item }">
            <v-switch v-model="item.ownerships.Active" dense hide-details></v-switch>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!activeStatus"
          :loading="loading"
          @click="updateUserData"
        >
          <v-icon left>mdi-content-save</v-icon>Update
        </v-btn>

        <v-btn
          color="warning"
          :disabled="!activeStatus || Conds === 'N'"
          @click="ChangeAuth(0)"
        >
          <v-icon left>mdi-close</v-icon>No Authorization
        </v-btn>

        <v-btn
          color="info"
          :disabled="!activeStatus || Conds === 'N'"
          @click="ChangeAuth(1)"
        >
          <v-icon left>mdi-book-open</v-icon>Read Only
        </v-btn>

        <v-btn
          color="success"
          :disabled="!activeStatus || Conds === 'N'"
          @click="ChangeAuth(2)"
        >
          <v-icon left>mdi-set-all</v-icon>Full Authorization
        </v-btn>
      </v-card-actions>
    </v-card>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: null,
    itemsPerPage: 10,
    dialog: false,
    searchUser: null,
    updateUserAuth: "",
    loading: false,
    loader: false,
    trueValue: true,
    userName: null,
    Conds: "Y",
    Queries: [],
    userGroups: [],
    dataOwnershipData: [],
    menuItems: [],
    users: [],
    selectedItem: null,
    items: [],
    allItems: [],
    authData: [],
    groupAuthData: [],
    activeStatus: false,
    activeUser: null,
    activeGroup: {},
    groupRights: [],
    UserID: null,
    authorizations: [
      { text: "Full", value: 2 },
      { text: "Readonly", value: 1 },
      { text: "None", value: 0 },
    ],
    userHeaders: [{ text: "Name", value: "name" }],
    headers: [
      { text: "Subject", value: "DocumentName" },
      { text: "Peer", value: "ownerships.peer" },
      { text: "Manager", value: "ownerships.manager" },
      { text: "Subordinate", value: "ownerships.subordinate" },
      { text: "Branch", value: "ownerships.branch" },
      { text: "Department", value: "ownerships.department" },
      { text: "Team", value: "ownerships.team" },
      { text: "Company", value: "ownerships.company" },
      { text: "Query", value: "id" },
      { text: "Active", value: "ownerships.Active" },
    ],
  }),
  methods: {
    ChangeAuth(val) {
      for (const auth of this.authData) {
        auth.ownerships.peer = val;
        auth.ownerships.manager = val;
        auth.ownerships.branch = val;
        auth.ownerships.department = val;
        auth.ownerships.team = val;
        auth.ownerships.company = val;
        auth.ownerships.subordinate = val;
      }
    },
    onUserChange() {
      this.activeStatus = false;
      this.getDocs();
    },
    getDocs() {
      const self = this;
      this.authData = [];
      this.loader = true;
      this.$store
        .dispatch("get", `/data-ownerships?UserID=${this.UserID}`)
        .then((res) => {
          if (res.ResultCode === 1200) {
            self.authData = res.ResponseData;
            self.activeStatus = true;
            self.loader = false;
            if (self.$refs.snackbar) {
              self.$refs.snackbar.show(res.ResultDesc, "success");
            }
          } else {
            self.loader = false;
            if (self.$refs.snackbar) {
              self.$refs.snackbar.show(res.ResultDesc, "error");
            }
          }
        })
        .catch((err) => {
          if (self.$refs.snackbar) {
            self.$refs.snackbar.show(err, "error");
          }
        });
    },
    updateUserData() {
      const self = this;
      const data = self.authData;
      const url = "/data-ownerships";
      this.loading = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.loading = false;
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "success");
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "error");
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "error");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "error");
        });
    },
    getQuery() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", "/query_manager")
        .then((res) => {
          self.Queries = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
        });
    },
  },
  created() {
    this.getUsers();
    this.getQuery();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.snackbar) {
        this.$refs.snackbar.show();
      }
    });
  },
};
</script>
